import { useState } from 'react';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { useOrgContext } from '../Organization/Details/Context';

export function AISettings(props: { className?: string }) {
  const { org, updateOrg } = useOrgContext();

  const originalFacts = org.settings?.ai?.facts ?? '';

  const [facts, setFacts] = useState(originalFacts);

  const {
    call: updateSettings,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async (facts: string) => {
    const resp = await apiService.organization.updateOrgSettings(org.id, {
      ai: {
        facts,
      },
    });
    updateOrg(resp.data.organization);
  });

  return (
    <div className={`flex flex-col gap-4 ${props.className ?? ''}`}>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-col gap-1'>
          <p className='font-bold text-base'>Global Facts</p>
          <p className='text-sms text-icon-gray'>
            Define default facts that Luna Park will follow in every AI
            conversation. This helps shape how Luna Park understands and
            responds to your questions.
          </p>
        </div>
        <textarea
          className='w-full h-64 p-4 field mb-0'
          value={facts}
          onChange={(e) => setFacts(e.target.value)}
          maxLength={5000}
          placeholder="Enter your organization's global facts here..."
        />
        <div className='flex flex-col items-end gap-1'>
          <button
            type='button'
            className='btn-primary w-30 h-10'
            onClick={() => updateSettings(facts)}
            disabled={isRunning || facts === originalFacts}
          >
            {isRunning ? 'Saving...' : 'Save'}
          </button>
          {error && <p className='text-sms text-red-500'>{error.message}</p>}
        </div>
      </div>
    </div>
  );
}
